import { NextRequest, NextResponse } from 'next/server'
import crypto from 'crypto'
import dayjs from 'dayjs'

import { formatarMoeda } from '@/components/CurrencyInput'

export const stringToColor = (str: string) => {
  str = str || ''
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  const color = '#' + (hash & 0x00ffffff).toString(16).padStart(6, '0')
  return color
}

export const getInitials = (name: string) => {
  name = name || ''
  const names = name.trim().split(' ')
  const initials =
    names[0].charAt(0).toUpperCase() +
    (names.length > 1 ? names[names.length - 1].charAt(0).toUpperCase() : '')
  return initials
}

export function capitalizeWords(value: string): string {
  return value
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const withLogging = (
  handler: (req: NextRequest) => Promise<NextResponse>
) => {
  return async (req: NextRequest) => {
    const reqClone = req.clone()
    const requestBody = await reqClone.text()

    console.log(
      `[INCOMING] ${req.cookies.get('sessionId')?.value ?? '-'} ${
        req.method
      } - ${req.nextUrl.toString()} - ${requestBody.slice(0, 200)}${
        requestBody.length > 200 ? '...' : ''
      }`
    )

    const res = await handler(req)

    const resClone = res.clone()
    const outputBody = await resClone.text()

    const truncatedBody =
      outputBody.length > 200 ? `${outputBody.slice(0, 200)}...` : outputBody

    const logItems = [
      '[OUTGOING]',
      req.cookies.get('sessionId')?.value ?? '-',
      req.method,
      req.nextUrl.toString(),
      res.status,
      truncatedBody,
    ]

    console.log(logItems.join(' - '))

    return res
  }
}

export function isValidCpf(input: string | number): boolean {
  const cpf = String(input).replace(/\D/g, '')

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false

  const calculateDigit = (base: number) => {
    const sum = cpf
      .slice(0, base - 1)
      .split('')
      .reduce((acc, digit, index) => acc + parseInt(digit) * (base - index), 0)

    const remainder = (sum * 10) % 11
    return remainder === 10 ? 0 : remainder
  }

  const firstDigitValid = calculateDigit(10) === parseInt(cpf[9])
  const secondDigitValid = calculateDigit(11) === parseInt(cpf[10])

  return firstDigitValid && secondDigitValid
}

export const formatCPF = (value: string) => {
  const numericValue = value.replace(/\D/g, '')
  if (numericValue.length > 9) {
    return numericValue.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{1,2}).*/,
      '$1.$2.$3-$4'
    )
  }
  if (numericValue.length > 6) {
    return numericValue.replace(/^(\d{3})(\d{3})(\d{1,3}).*/, '$1.$2.$3')
  }
  if (numericValue.length > 3) {
    return numericValue.replace(/^(\d{3})(\d{1,3}).*/, '$1.$2')
  }
  return numericValue
}

export function isValidCnpj(cnpj: string): boolean {
  if (!/^\d{14}$/.test(cnpj)) return false
  let size = cnpj.length - 2
  let numbers = cnpj.substring(0, size)
  let digits = cnpj.substring(size)
  let sum = 0
  let pos = size - 7
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--
    if (pos < 2) pos = 9
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result !== parseInt(digits.charAt(0))) return false
  size = size + 1
  numbers = cnpj.substring(0, size)
  sum = 0
  pos = size - 7
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--
    if (pos < 2) pos = 9
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  return result === parseInt(digits.charAt(1))
}

export const currencyFormatter = value => {
  const numericValue = isNaN(Number(value)) ? 0 : Number(value)

  return `R$ ${formatarMoeda(
    numericValue.toFixed(2).replace('.', ''),
    '.',
    ','
  )}`
}

export function detectarMudancas(
  itemOriginal,
  data,
  camposIgnorados = [],
  caminho = '',
  mudancas = []
) {
  const todasChaves = new Set([
    ...Object.keys(itemOriginal || {}),
    ...Object.keys(data || {}),
  ])

  const formatarValor = valor => {
    if (valor === true) return '>Verdadeiro<'
    if (valor === false) return '>Falso<'
    if (valor === undefined || valor === null || valor === '') return '>vazio<'
    if (Array.isArray(valor))
      return valor.length ? `>${valor.join(', ')}<` : '>vazio<'
    return `>${valor}<`
  }

  todasChaves.forEach(key => {
    const caminhoCompleto = caminho ? `${caminho}.${key}` : key

    // Verifica se o campo atual deve ser ignorado
    if (camposIgnorados.includes(caminhoCompleto)) return

    const valorAntigo = itemOriginal ? itemOriginal[key] : undefined
    const valorNovo = data ? data[key] : undefined

    if (
      typeof valorAntigo === 'object' &&
      valorAntigo !== null &&
      typeof valorNovo === 'object' &&
      valorNovo !== null
    ) {
      if (Array.isArray(valorAntigo) && Array.isArray(valorNovo)) {
        // Comparação de arrays
        const arrayAntigo = formatarValor(valorAntigo)
        const arrayNovo = formatarValor(valorNovo)
        if (arrayAntigo !== arrayNovo) {
          mudancas.push(
            `${caminhoCompleto} de ${arrayAntigo} para ${arrayNovo}`
          )
        }
      } else {
        // Ambos são objetos, chamada recursiva
        detectarMudancas(
          valorAntigo,
          valorNovo,
          camposIgnorados,
          caminhoCompleto,
          mudancas
        )
      }
    } else {
      // Tratamento de undefined ou null
      const valorAntigoFormatado = formatarValor(valorAntigo)
      const valorNovoFormatado = formatarValor(valorNovo)

      if (valorAntigoFormatado !== valorNovoFormatado) {
        mudancas.push(
          `${caminhoCompleto} de ${valorAntigoFormatado} para ${valorNovoFormatado}`
        )
      }
    }
  })

  return mudancas.join(', ')
}

interface DuplicateKeyError extends Error {
  code: number
  keyValue: { [key: string]: any }
}

export function handleError(error: any) {
  console.error('Erro:', error)

  if (error.code === 11000) {
    const duplicateError = error as DuplicateKeyError
    const duplicatedField = Object.keys(duplicateError.keyValue)[0]
    const duplicatedValue = duplicateError.keyValue[duplicatedField]

    return NextResponse.json(
      {
        message:
          'Um registro com essas informações já existe. Por favor, verifique e tente novamente.',
      },
      { status: 409 }
    )
  }

  return NextResponse.json(
    {
      message:
        'Ocorreu um erro no processamento da sua requisição. Tente novamente mais tarde',
    },
    { status: 500 }
  )
}

export const darkenColor = (color, amount) => {
  const colorNum = parseInt(color.slice(1), 16)
  const r = Math.max((colorNum >> 16) - amount, 0)
  const g = Math.max(((colorNum >> 8) & 0x00ff) - amount, 0)
  const b = Math.max((colorNum & 0x0000ff) - amount, 0)
  return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`
}

export function generatePaymentHash(
  data_pagamento: string,
  valor_pago: number,
  metodo_pagamento: string
): string {
  return crypto
    .createHash('sha256')
    .update(`${data_pagamento}-${valor_pago}-${metodo_pagamento}`)
    .digest('hex')
}

export function convertBRL2Float(value) {
  const cleaned = value
    .replace(/\s/g, '')
    .replace('R$', '')
    .replace(/[.,](?=\d{3})/g, '')
    .replace(',', '.')

  return parseFloat(cleaned)
}

export const valorPorExtenso = (valor: number): string => {
  const unidades = [
    'zero',
    'um',
    'dois',
    'três',
    'quatro',
    'cinco',
    'seis',
    'sete',
    'oito',
    'nove',
    'dez',
    'onze',
    'doze',
    'treze',
    'quatorze',
    'quinze',
    'dezesseis',
    'dezessete',
    'dezoito',
    'dezenove',
  ]
  const dezenas = [
    '',
    '',
    'vinte',
    'trinta',
    'quarenta',
    'cinquenta',
    'sessenta',
    'setenta',
    'oitenta',
    'noventa',
  ]
  const centenas = [
    '',
    'cem',
    'duzentos',
    'trezentos',
    'quatrocentos',
    'quinhentos',
    'seiscentos',
    'setecentos',
    'oitocentos',
    'novecentos',
  ]
  const escala = ['', 'mil', 'milhão', 'bilhão', 'trilhão']

  const inteiro = Math.floor(valor)
  const resto = Math.round((valor - inteiro) * 100)
  const partes: string[] = []
  const escreveValor = (num: number): string => {
    if (num === 0) {
      return ''
    }
    if (num < 20) {
      return unidades[num]
    }
    if (num < 100) {
      const d = Math.floor(num / 10)
      const u = num % 10
      return u === 0 ? dezenas[d] : dezenas[d] + ' e ' + unidades[u]
    }
    if (num === 100) {
      return 'cem'
    }
    const c = Math.floor(num / 100)
    const r = num % 100
    return r === 0 ? centenas[c] : centenas[c] + ' e ' + escreveValor(r)
  }

  const separarMilhares = (n: number): number[] => {
    const arr = []
    while (n > 0) {
      arr.push(n % 1000)
      n = Math.floor(n / 1000)
    }
    return arr.reverse()
  }

  if (inteiro === 0) {
    partes.push('zero')
  } else {
    const grupos = separarMilhares(inteiro)
    grupos.forEach((g, i) => {
      if (g === 0) {
        return
      }
      let s = escreveValor(g)
      const e = escala[grupos.length - i - 1]
      if (e === 'mil' && g === 1) {
        s = 'mil'
      } else if (e !== '') {
        s +=
          ' ' +
          e +
          (g > 1 && (e === 'milhão' || e === 'bilhão' || e === 'trilhão')
            ? 'es'
            : '')
      }
      partes.push(s)
    })
  }

  let extenso = partes.join(' e ')
  if (resto > 0) {
    extenso += ' e ' + escreveValor(resto) + ' centavos'
  }

  return extenso
}

const mesPorExtenso = (mes: number): string => {
  const meses = [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ]
  return meses[mes - 1]
}

export const dataPorExtenso = (data: string): string => {
  const d = dayjs(data)
  const dia = d.date()
  const mes = mesPorExtenso(d.month() + 1)
  const ano = d.year()
  const anoExtenso = anoPorExtenso(ano)
  const diaExtenso = numeroPorExtenso(dia)
  const mesUpper = mes.toUpperCase()
  return `No dia ${diaExtenso.toUpperCase()} de ${mesUpper} de ${anoExtenso.toUpperCase()}`
}

export const numeroPorExtenso = (num: number): string => {
  const basicos = {
    0: 'zero',
    1: 'um',
    2: 'dois',
    3: 'três',
    4: 'quatro',
    5: 'cinco',
    6: 'seis',
    7: 'sete',
    8: 'oito',
    9: 'nove',
    10: 'dez',
    11: 'onze',
    12: 'doze',
    13: 'treze',
    14: 'quatorze',
    15: 'quinze',
    16: 'dezesseis',
    17: 'dezessete',
    18: 'dezoito',
    19: 'dezenove',
  }
  if (basicos[num]) {
    return basicos[num]
  }
  const dezenas = [
    'vinte',
    'trinta',
    'quarenta',
    'cinquenta',
    'sessenta',
    'setenta',
    'oitenta',
    'noventa',
  ]
  if (num < 100) {
    const d = Math.floor(num / 10)
    const u = num % 10
    if (u === 0) {
      return dezenas[d - 2]
    }
    return dezenas[d - 2] + ' e ' + basicos[u]
  }
  const centenas = [
    'cem',
    'duzentos',
    'trezentos',
    'quatrocentos',
    'quinhentos',
    'seiscentos',
    'setecentos',
    'oitocentos',
    'novecentos',
  ]
  if (num < 1000) {
    const c = Math.floor(num / 100)
    const r = num % 100
    if (r === 0) {
      return centenas[c - 1]
    }
    return centenas[c - 1] + ' e ' + numeroPorExtenso(r)
  }
  if (num < 1000000) {
    const m = Math.floor(num / 1000)
    const r = num % 1000
    const prefixo = m === 1 ? 'mil' : numeroPorExtenso(m) + ' mil'
    if (r === 0) {
      return prefixo
    }
    return prefixo + ' ' + numeroPorExtenso(r)
  }
  return num.toString()
}

export const anoPorExtenso = (ano: number): string => {
  const milhar = Math.floor(ano / 1000)
  const resto = ano % 1000
  let ext = numeroPorExtenso(milhar * 1000)
  if (resto > 0) {
    ext += ' e ' + numeroPorExtenso(resto)
  }
  return ext
}
