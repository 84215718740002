'use client';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useRouter } from 'next/navigation';
import { Layout, Form, Input, Button, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import * as Sentry from '@sentry/browser';
import Carregando from '@/components/Carregando';
import { formatCPF, isValidCpf } from '@/utils';
import { useAuth } from '@/components/AuthContext';
export default function LoginPage() {
  const {
    user,
    isLoading
  } = useAuth();
  const [form] = Form.useForm();
  const [isRegister, setIsRegister] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const router = useRouter();
  useEffect(() => {
    if (user) {
      window.location.href = '/inicio';
    }
  }, [user, router]);
  useEffect(() => {
    if (redirecting) {
      window.location.href = '/inicio';
    }
  }, [redirecting, router]);
  const handleSubmit = async values => {
    setLoading(true);
    const url = isRegister ? '/api/cadastrar' : '/api/autenticar';
    try {
      const response = await axios.post(url, values);
      message.success(response.data.message);
      Sentry.setUser({
        cpf: values.cpf
      });
      setRedirecting(true);
    } catch (error) {
      message.error(error.response?.data?.message || 'Erro inesperado');
    } finally {
      setLoading(false);
    }
  };
  const validateFullName = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Por favor, insira um nome válido'));
    }
    const parts = value.trim().split(' ');
    if (parts.length < 2 || parts[0].length <= 1) {
      return Promise.reject(new Error('Por favor, insira um nome válido'));
    }
    return value.length >= 10 ? Promise.resolve() : Promise.reject(new Error('Nome completo deve ter no mínimo 10 caracteres'));
  };
  if (isLoading || loading || redirecting || user) {
    return <Carregando />;
  }
  return <>
      <Layout className={'sm:max-w-[1000px] w-full mx-auto'} style={{
      minHeight: '100vh',
      flex: 1
    }} data-sentry-element="Layout" data-sentry-source-file="page.tsx">
        <Content className="flex justify-center items-center p-[20px]" style={{
        flexDirection: 'column'
      }} data-sentry-element="Content" data-sentry-source-file="page.tsx">
          <div className="pb-[20px] ">
            <img width={200} src="/assets/images/solbra-light.svg" />
          </div>

          <div className="bg-white w-full max-w-md p-8 rounded-lg shadow-md">
            <h1 className="text-3xl font-semibold text-center mb-6 text-gray-800">
              {isRegister ? 'Registrar' : 'Autenticar'}
            </h1>

            <Form form={form} layout="vertical" onFinish={handleSubmit} className="space-y-6" data-sentry-element="Form" data-sentry-source-file="page.tsx">
              <Form.Item label="CPF" name="cpf" rules={[{
              required: true,
              message: 'Por favor, insira seu CPF'
            }, {
              validator: (_, value) => isValidCpf(value) ? Promise.resolve() : Promise.reject(new Error('CPF inválido'))
            }]} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
                <Input placeholder="Digite seu CPF" className="py-2 px-4 rounded-lg" disabled={loading} maxLength={14} onChange={e => {
                form.setFieldsValue({
                  cpf: formatCPF(e.target.value)
                });
              }} data-sentry-element="Input" data-sentry-source-file="page.tsx" />
              </Form.Item>

              <Form.Item label="Senha" name="senha" rules={[{
              required: true,
              message: 'Por favor, insira sua senha'
            }]} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
                <Input.Password visibilityToggle={false} placeholder="Digite sua senha" className="py-2 px-4 rounded-lg" disabled={loading} data-sentry-element="unknown" data-sentry-source-file="page.tsx" />
              </Form.Item>

              {isRegister && <Form.Item label="Nome" name="nome" rules={[{
              required: true,
              message: 'Por favor, insira seu nome'
            }, {
              validator: validateFullName
            }]}>
                  <Input placeholder="Digite seu nome completo" className="py-2 px-4 rounded-lg" disabled={loading} />
                </Form.Item>}

              <Button type="primary" htmlType="submit" className="w-full py-3 text-lg font-semibold rounded-lg bg-blue-600 hover:bg-blue-700 text-white" loading={loading} data-sentry-element="Button" data-sentry-source-file="page.tsx">
                {isRegister ? 'Registrar' : 'Entrar'}
              </Button>
            </Form>
          </div>
          <Typography.Text className="pt-[20px] font-bold cursor-pointer text-white" onClick={() => setIsRegister(!isRegister)} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
            {isRegister ? 'Já tem conta? Entrar' : 'Não tem conta? Registrar'}
          </Typography.Text>
        </Content>
      </Layout>
    </>;
}