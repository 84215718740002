'use client';

import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
export const formatarMoeda = (value, separadorMilhar, separadorDecimal) => {
  let numeroLimpo = value.replace(/\D/g, '');
  if (!numeroLimpo) return '';
  let parteInteira = numeroLimpo.slice(0, -2);
  let parteDecimal = numeroLimpo.slice(-2);
  parteInteira = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, separadorMilhar);
  return `${parteInteira}${separadorDecimal}${parteDecimal}`;
};
const CurrencyInput = ({
  min,
  max,
  value,
  onChange,
  disabled,
  style
}: {
  min: number;
  max: number;
  value: number;
  onChange: (value) => void;
  disabled?: boolean;
  style?: any;
}) => {
  const [inputValue, setInputValue] = useState(`R$ ${formatarMoeda((value || 0).toFixed(2).replace('.', ''), '.', ',')}`);
  useEffect(() => {
    if (value !== undefined) {
      setInputValue(`R$ ${formatarMoeda((value || 0).toFixed(2).replace('.', ''), '.', ',')}`);
    }
  }, [value]);
  const handleInput = event => {
    const valorBruto = event.target.value;
    const valorNumerico = Number(valorBruto.replace(/[^\d]/g, '')) / 100;
    if (valorNumerico >= (min || 0) && (max === undefined || valorNumerico <= max)) {
      const valorFormatado = `R$ ${formatarMoeda(valorBruto.replace(/[^\d]/g, ''), '.', ',')}`;
      setInputValue(valorFormatado);
      if (onChange) {
        onChange(valorNumerico);
      }
    } else if (valorNumerico > max) {
      setInputValue(`R$ ${formatarMoeda((max * 100).toString(), '.', ',')}`);
      if (onChange) {
        onChange(max);
      }
    }
  };
  return <Input value={inputValue} placeholder="Digite um valor" disabled={disabled} onInput={handleInput} style={style} data-sentry-element="Input" data-sentry-component="CurrencyInput" data-sentry-source-file="CurrencyInput.tsx" />;
};
CurrencyInput.defaultProps = {
  min: 0,
  max: undefined,
  value: undefined,
  onChange: null
};
export default CurrencyInput;