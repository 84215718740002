'use client';

import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Empresa } from '@/app/vendas/ponto_de_venda/types';
import { INotificacao } from '@/models/Notificacoes';
interface Usuario {
  id: string;
  cpf: string;
  nome: string;
}
interface AuthContextType {
  user: Usuario | null;
  empresas: Empresa[];
  empresaSelecionada: Empresa | null;
  setEmpresaSelecionada: (empresa: Empresa | null) => void;
  notificacoes: INotificacao[];
  isLoading: boolean;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);
export const AuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const [user, setUser] = useState<Usuario | null>(null);
  const [empresas, setEmpresas] = useState<Empresa[]>([]);
  const [empresaSelecionada, setEmpresaSelecionada] = useState<Empresa | null>(null);
  const [notificacoes, setNotificacoes] = useState<INotificacao[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get('/api/inicio');
        const {
          usuario,
          empresas
        } = response.data;
        setUser(usuario);
        setEmpresas(empresas);
        const cnpjCookie = document.cookie?.split('; ')?.find(cookie => cookie.startsWith('x-empresa-cnpj='))?.split('=')[1];
        if (cnpjCookie) {
          const empresa = empresas.find(e => e.cnpj === cnpjCookie);
          setEmpresaSelecionada(empresa || empresas[0] || null);
        } else {
          setEmpresaSelecionada(empresas[0] || null);
        }
      } catch {
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchNotificacoes = async () => {
      try {
        const response = await axios.get('/api/notificacoes');
        setNotificacoes(response.data.data);
      } catch (error) {
        console.error('Erro ao buscar notificações:', error);
      }
    };
    fetchUser();
    fetchNotificacoes();
    const intervalId = setInterval(fetchNotificacoes, 60000);
    return () => clearInterval(intervalId);
  }, []);
  return <AuthContext.Provider value={{
    user,
    empresas,
    empresaSelecionada,
    setEmpresaSelecionada,
    notificacoes,
    isLoading
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="AuthContext.tsx">
      {children}
    </AuthContext.Provider>;
};
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }
  return context;
};